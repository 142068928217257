@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

/* body {
    cursor: none;
}

.cursor {
    transition: transform 250ms ease-in-out;
}

.cursor.hover {
    transform: translate(-50%, -50%) scale(4);
    transition: transform 250ms ease-in-out;
}

.cursor.inner.hover {
    background: #f7f7f7;
    mix-blend-mode: difference;
} */

/* Custom scrollbar styling for WebKit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
    width: 12px;
    background-color: black;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* From Uiverse.io by alexmaracinaru */ 
.cta {
    position: relative;
    /* margin: auto; */
    padding: 10px 15px;
    transition: all 0.2s ease;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .cta:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    background: #0e2257;
    width: 40px;
    height: 40px;
    transition: all 0.3s ease;
  }
  
  .cta span {
    position: relative;
    /* font-family: "Ubuntu", sans-serif; */
    /* font-size: 18px; */
    /* font-weight: 700; */
    /* letter-spacing: 0.05em; */
    color: #ffffff;
  }
  
  .cta svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #f7ff0b;
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all 0.3s ease;
  }
  
  .cta:hover:before {
    width: 100%;
    background: #0e2257;
  }
  
  .cta:hover svg {
    transform: translateX(0);
  }
  
  .cta:active {
    transform: scale(0.95);
  }

/* Demo Icon Spin Animation */
.demoicon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.demoicon svg {
  transition: transform 0.5s ease-in-out;
}

.demoicon:hover svg {
  transform: rotate(360deg) scale(1.3);
}
table td{
  text-align: left;
  vertical-align: top;
  /* padding-left: 15px; */
  /* text-wrap: nowrap; */
}
/* Fullscreen loader container */
.fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black; /* Light background with slight opacity */
  z-index: 1000;
}

/* Loader animation */
.loader {
  width: 65px;
  aspect-ratio: 1;
  position: relative;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  border-radius: 50px;
  box-shadow: 0 0 0 3px inset white; /* Adjust color if needed */
  animation: l4 2.5s infinite;
}

.loader:after {
  animation-delay: -1.25s;
}

@keyframes l4 {
  0% {
    inset: 0 35px 35px 0;
  }
  12.5% {
    inset: 0 35px 0 0;
  }
  25% {
    inset: 35px 35px 0 0;
  }
  37.5% {
    inset: 35px 0 0 0;
  }
  50% {
    inset: 35px 0 0 35px;
  }
  62.5% {
    inset: 0 0 0 35px;
  }
  75% {
    inset: 0 0 35px 35px;
  }
  87.5% {
    inset: 0 0 35px 0;
  }
  100% {
    inset: 0 35px 35px 0;
  }
}


   
  @keyframes slide-down {
    0% {
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  